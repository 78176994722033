// EventObject
// 用于存储活动对象

import { defineStore } from "pinia";
import type { Event } from "@/types/payload-types";
export const useEventObjectStore = defineStore("eventObject", {
  state: () => {
    return {
      eventData: {} as Event,
      drawer: null,
    };
  },
  actions: {
    // 设置活动对象
    setData(data: Event) {
      this.eventData = data;
    },
  },
});
